<template>
  <v-row v-scroll="onScroll">
    <!-- header -->
    <mini-header title="Información del folio" :breadcrumbs="breadcrumbs" :breadcrumbsItemDinamic="{ loading: false, text: nameDocumentsType($route.params.id) }" :dense="true" :hiddenTab="true" :addPadding="true" :scroll="scroll">
      <template v-slot:main>
        <v-query-builder :query-map="filters" model="folios" />
      </template>
    </mini-header>
    <!-- end header -->
    <v-col cols="12" class="px-0">
      <skeleton-list v-if="loading" />
      <template v-else>
        <v-empty-state v-if="!cafsList.length" title="No existen disponibilidad de folios para mostrar" type="documents" id="folios" :isFree="true" customClass="mt-n8" />
        <template v-else>
          <v-data-table :class="{'fixed-table' : $store.state.base.isExpandNavigationDrawer}" :headers="currentHeader" :items="cafsList" hide-default-header disable-sort mobile-breakpoint hide-default-footer fixed-header :height="heightTable">
            <template v-slot:header="{ props: { headers } }">
              <VTableHeaders :headers="headers" />
              <v-divider style="position: absolute; z-index: 2;  margin-top: -6px; margin-left: -5px; min-width: 100%" />
            </template>

            <template v-slot:[`item.created`]="{item}">
              <span class="body-1">{{item.created | date}}</span>
            </template>
            <template v-slot:[`item.life_from`]="{item}">
              <span class="body-1">{{item.life_from | date}} - {{item.life_to | date}}</span>
            </template>
            <template v-slot:[`item.total`]="{item}">
              <span class="body-1 mr-n1">{{item.total | number}}</span>
            </template>
            <template v-slot:[`item.from_number`]="{item}">
              <span class="body-1 mr-n1">{{item.from_number}} - {{item.to_number}}</span>
            </template>
            <template v-slot:[`item.available_to_give`]="{item}">
              <v-icon v-if="!item.available_to_give" color="success">mdi-check-circle-outline</v-icon>
              <v-icon v-else x-small color="secondary">mdi-minus</v-icon>
            </template>

            <template v-slot:[`item.available_to`]="{item}">
              <v-icon v-if="item.available_to_give" color="success">mdi-check-circle-outline</v-icon>
              <v-icon v-else color="secondary">mdi-minus</v-icon>
            </template>

            <template v-slot:[`item.actions`]="{item}">
              <v-menu close-on-click close-on-content-click offset-y left :nudge-bottom="10" transition="slide-y-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mr-n4" v-bind="attrs" v-on="on" text @click="current = item" x-small><v-icon color="fontBody" size="24">mdi-dots-vertical</v-icon></v-btn>
                </template>
                <v-list class="pa-0">
                  <v-list-item class="px-3" @click="download(item.data)" :ripple="false">
                    <v-list-item-title class="body-2"><v-icon color="secondary" size="20" left>mdi-code-braces-box</v-icon>Descargar XML</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
        </v-data-table>
        <VMainPagination :count="count" />
        </template>
      </template>
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import { saveAs } from 'file-saver'
import SkeletonList from '@/components/commons/skeletonLoader/SkeletonList'
import MiniHeader from '@/components/commons/MiniHeader'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import ListViewMixin from '@/mixins/ListViewMixin'
import documentTypesList from '@/collections/documentTypes'
import VEmptyState from '@/components/commons/VEmptyState'
import VMainPagination from '@/components/commons/VMainPagination'

export default {
  components: {
    VEmptyState,
    MiniHeader,
    VMainPagination,
    SkeletonList,
    VQueryBuilder: () => import('@/components/VQueryBuilder/VQueryBuilder'),
    VTableHeaders: () => import('@/components/VTable/VTableHeaders')
  },
  mixins: [
    GenericViewMixin, ListViewMixin
  ],
  data: () => ({
    scroll: 0,
    loading: false,
    documentTypes: documentTypesList.filter(d => d.country === 'CL'),
    headers: [
      { text: 'Fecha', value: 'created', align: 'end', sortable: true, width: 50 },
      // { text: 'Usuario', value: 'email' },
      { text: 'Vigencia', value: 'life_from', align: 'end', sortable: true },
      { text: 'Rango', value: 'from_number', align: 'end' },
      { text: 'Total', value: 'total', align: 'end' },
      { text: '', value: 'actions', align: 'end', width: 30 }
    ],
    columnSupport: [
      { text: 'WS/Agente', value: 'available_to_give', align: 'center' },
      { text: 'Plataforma', value: 'available_to', align: 'center' }
    ],
    filters: [
    {
        type: 'date',
        id: 'created',
        label: 'Fecha de creación',
        value: null,
        value2: null,
        category: 'a',
        operator: null,
        operators: ['es igual', 'es menor a', 'es mayor a', 'rango']
      },
      {
        type: 'date',
        id: 'life',
        label: 'Fecha de vigencia',
        value: null,
        value2: null,
        category: 'a',
        operator: null,
        operators: ['es igual', 'es menor a', 'es mayor a', 'rango']
      }
      // {
      //   type: 'multiple',
      //   id: 'number',
      //   label: 'CAF',
      //   value: null,
      //   value2: null,
      //   category: 'b',
      //   choices: []
      // }
    ],
    breadcrumbs: {
      main: 'Folios',
      children: [
        {
          text: 'Disponibilidad',
          route: 'FoliosListCreate'
        }
      ]
    },
    env: process.env?.VUE_APP_MODE ?? 'ax'
  }),
  computed: {
    heightTable () {
      if (this.$vuetify.breakpoint.height <= 1200 && this.cafsList.length >= 16 && this.cafsList.length <= 20) return 'calc(100vh - 210px)'
      if (this.cafsList.length > 20) return 'calc(100vh - 165px)'
      if (this.cafsList.length < 16) return 'auto'
      return 'calc(100vh - 275px)'
    },
    ...mapState({
      cafsList: state => state.irs.cafsList,
      count: state => state.irs.cafsCount
    }),
    currentHeader () {
      // if (this.$store.state.auth.userAccount.is_staff) {
        const array = this.headers.slice(0, 4)
        return [...array, ...this.columnSupport, ...[this.headers[4]]]
      // }
      // return this.headers
    }
  },
  created () {
    this.getList()
  },
  methods: {
    onScroll (e) {
      this.scroll = e.target?.scrollingElement?.scrollTop ?? 0
    },
    getList () {
      this.loading = true
      this.$store.dispatch('irs/LIST', {
        resource: 'irs/sii/cafs',
        query: {
          ...this.$route.query,
          document_type: this.$route.params.id
        }
      })
      .then(() => {
        // this.setFilterCaf()
      })
      .finally(() => {
        this.loading = false
      })
    },
    setFilterCaf () {
      if (this.cafsList.length) {
        const index = this.filters.findIndex(({id}) => id === 'number')
        /* eslint-disable */
        this.filters[index].choices = this.cafsList.map(({from_number, to_number}) => {
          return {
            label: `${from_number} - ${to_number}`,
            value: `${from_number}-${to_number}`
          }
        })
      }
    },
    download (data = null) {
      if (data) {
        const content = Buffer.from(data, 'base64')
        saveAs(new Blob([content], {type: 'application/xml'}), 'Detalle stock de folios.xml')
        this.$dialog.message.info('La exportación se realizó con éxito')
      } else {
        this.$dialog.message.error('Ha ocurrido un error al descargar el xml')
      }
    }
  }
}
</script>